<template>
  <div class="site-top-bar">
    <a href="/app/home" class="logo">
      <img  src="./assets/factory-logo-2.png" alt="site-logo">
    </a>
    <a href="/app/home" class="title">
      <span>HOUSAM FACTORY</span>
    </a>
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss">
@media print {
  .page-handle-printing {
    .site-top-bar {
      display: none;
    }
  }
}
.site-top-bar {
  background-color: hsl(225, 90%, 20%);
  color: white;
  > .title {
    font-weight: bold;
    padding: 2px 8px;
    text-decoration: none;
    color: white;
  }
  > .logo {
    display: inline-block;
    background-color: white;
    border-radius: 4px;
    margin: 3px;
    margin-inline-start: 10px;
    vertical-align: middle;

    > img {
      display: block;
      width: 1.5em;
      height: 1.5em;
      padding: 2px;
    }
  }
}

</style>
