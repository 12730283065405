function request_error(type: string, error_name: string, error_details: any): never {
  console.error("Request Error",{type, error_name, error_details});
  throw new Error(`${error_name}`);
}

export class ApiRequestAbort extends Error {
  constructor(public request_data: any){
    super("ApiRequestAbort");
  }
}

function getCookie(name: string) {
  let cookie: {[key: string]: string} = {};
  document.cookie.split(';').forEach(function(el) {
    let split = el.split('=');
    cookie[split[0].trim()] = split.slice(1).join("=");
  })
  return cookie[name];
}
export async function api_request(command_name: string, command_data: any, abort_signal?: AbortSignal){
  const data = {
    name: command_name,
    data: command_data,
  };

  let response: Response;
  try {
    response = await fetch(`/api`, {
      method: "POST",
      body: JSON.stringify(data),
      signal: abort_signal,
      headers: {
        'csrf-token': getCookie('csrf-token')
      },
    });
  } catch(e) {
    if(e instanceof DOMException && e.name === 'AbortError') {
      throw new ApiRequestAbort(data);
    } else {
      request_error('REQUEST-ERROR', 'FETCH-ERROR', e);
    }
  }

  let response_str: string | null = null;
  try {
    response_str = await response.text();
  } catch(e) {
    if(e instanceof DOMException && e.name === 'AbortError') {
      throw new ApiRequestAbort(data);
    } else {
      request_error('REQUEST-ERROR', 'RESPONSE-RECEIVE-ERROR', e);
    }
  }

  let response_data: any = null;
  try {
    response_data = JSON.parse(response_str);
  } catch(e) {
    request_error('REQUEST-ERROR', 'RESPONSE-NOT-VALID-JSON', e);
  }

  if(!response_data || typeof response_data !== 'object' || Array.isArray(response_data)) {
    request_error('REQUEST-ERROR', 'RESPONSE-NOT-OBJECT', {response_data});
  }

  let v = response_data.v;
  let major = Math.trunc(v / 1000);
  let minor = v % 1000;

  if(major === 1) {
    if(response_data.ok) {
      return response_data.output;
    } else {
      request_error('REQUEST-ERROR', 'RESPONSE-NOT-OK', response_data);
    }
  } else {
    request_error('REQUEST-ERROR', 'RESPONSE-VERSION-NOT-SUPPORTED', {version: v});
  }
}

